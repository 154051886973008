.error-section .container {
  height: 66lvh;
}

.error-section .row {
  height: 100%;
}

.error-section h4 {
  font-family: "Figtree Bold", sans-serif;
  text-align: center;
  color: white;
  font-size: 34px;
  margin-bottom: .5rem;
  letter-spacing: 0.66px;
  line-height: 1.33;
}

@include media-breakpoint-up(lg) {
  .error-section h4 {
  }
}

.error-section p {
  text-align: center;
  color: white;
  font-family: "Figtree light", sans-serif;
  font-size: 18px;
  line-height: 1.25;
  letter-spacing: 0.5px;
}
@include media-breakpoint-up(lg) {
  .error-section p {
  }
}
.error-button {
  width: auto !important;
}