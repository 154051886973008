@font-face {
  font-family: "Figtree Black";
  src: url("../fonts/Figtree-Black.woff2") format("woff2"), url("../fonts/Figtree-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Figtree Light";
  src: url("../fonts/Figtree-Light.woff2") format("woff2"), url("../fonts/Figtree-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Figtree Bold";
  src: url("../fonts/Figtree-Bold.woff2") format("woff2"), url("../fonts/Figtree-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Figtree ExtraBold";
  src: url("../fonts/Figtree-ExtraBoldItalic.woff2") format("woff2"), url("../fonts/Figtree-ExtraBoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Figtree Medium";
  src: url("../fonts/Figtree-Medium.woff2") format("woff2"), url("../fonts/Figtree-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Figtree";
  src: url("../fonts/Figtree-Italic.woff2") format("woff2"), url("../fonts/Figtree-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Figtree Bold";
  src: url("../fonts/Figtree-BoldItalic.woff2") format("woff2"), url("../fonts/Figtree-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Figtree Light";
  src: url("../fonts/Figtree-LightItalic.woff2") format("woff2"), url("../fonts/Figtree-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Figtree ExtraBold";
  src: url("../fonts/Figtree-ExtraBold.woff2") format("woff2"), url("../fonts/Figtree-ExtraBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Figtree Black";
  src: url("../fonts/Figtree-BlackItalic.woff2") format("woff2"), url("../fonts/Figtree-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Figtree Medium";
  src: url("../fonts/Figtree-MediumItalic.woff2") format("woff2"), url("../fonts/Figtree-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Figtree SemiBold";
  src: url("../fonts/Figtree-SemiBoldItalic.woff2") format("woff2"), url("../fonts/Figtree-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Figtree SemiBold";
  src: url("../fonts/Figtree-SemiBold.woff2") format("woff2"), url("../fonts/Figtree-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Figtree Regular";
  src: url("../fonts/Figtree-Regular.woff2") format("woff2"), url("../fonts/Figtree-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #2E60FF;
  --bs-btn-border-color: #2E60FF;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2752d9;
  --bs-btn-hover-border-color: #254dcc;
  --bs-btn-focus-shadow-rgb: 77, 120, 255;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #254dcc;
  --bs-btn-active-border-color: #2348bf;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #2E60FF;
  --bs-btn-disabled-border-color: #2E60FF;
}

.btn-secondary {
  --bs-btn-color: #000;
  --bs-btn-bg: #D9E2FF;
  --bs-btn-border-color: #D9E2FF;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #dfe6ff;
  --bs-btn-hover-border-color: #dde5ff;
  --bs-btn-focus-shadow-rgb: 184, 192, 217;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #e1e8ff;
  --bs-btn-active-border-color: #dde5ff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #D9E2FF;
  --bs-btn-disabled-border-color: #D9E2FF;
}

.btn-tertiary {
  --bs-btn-color: #000;
  --bs-btn-bg: #FF8F5C;
  --bs-btn-border-color: #FF8F5C;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffa074;
  --bs-btn-hover-border-color: #ff9a6c;
  --bs-btn-focus-shadow-rgb: 217, 122, 78;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffa57d;
  --bs-btn-active-border-color: #ff9a6c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #FF8F5C;
  --bs-btn-disabled-border-color: #FF8F5C;
}

.btn-outline-primary {
  --bs-btn-color: #2E60FF;
  --bs-btn-border-color: #2E60FF;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2E60FF;
  --bs-btn-hover-border-color: #2E60FF;
  --bs-btn-focus-shadow-rgb: 46, 96, 255;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #2E60FF;
  --bs-btn-active-border-color: #2E60FF;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #2E60FF;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #2E60FF;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #D9E2FF;
  --bs-btn-border-color: #D9E2FF;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #D9E2FF;
  --bs-btn-hover-border-color: #D9E2FF;
  --bs-btn-focus-shadow-rgb: 217, 226, 255;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #D9E2FF;
  --bs-btn-active-border-color: #D9E2FF;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #D9E2FF;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #D9E2FF;
  --bs-gradient: none;
}

.btn-outline-tertiary {
  --bs-btn-color: #FF8F5C;
  --bs-btn-border-color: #FF8F5C;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #FF8F5C;
  --bs-btn-hover-border-color: #FF8F5C;
  --bs-btn-focus-shadow-rgb: 255, 143, 92;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #FF8F5C;
  --bs-btn-active-border-color: #FF8F5C;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FF8F5C;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #FF8F5C;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: white;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: white;
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 77, 120, 255;
  text-decoration: underline;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}

.btn-sm {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: var(--bs-border-radius-sm);
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), 0.8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='%23FD824E' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: #FEC59F;
  --bs-navbar-toggler-border-radius: var(--bs-border-radius);
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container-xxl, .navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark,
.navbar[data-bs-theme=dark] {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme=dark] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 340px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: var(--bs-border-width);
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: var(--bs-box-shadow-sm);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.5;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: var(--bs-offcanvas-transition);
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin-top: calc(-0.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-0.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-0.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(46, 96, 255, 0.25);
}
.nav-link.disabled, .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #2E60FF;
}
.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}
.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
}
.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}
.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 700;
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.container,
.container-fluid,
.container-xxl {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1440px;
  }
}
html {
  overflow-x: hidden !important;
  scroll-snap-type: y mandatory;
  overscroll-behavior-y: none;
  overflow-y: auto;
  scroll-behavior: auto !important;
}

body {
  padding-top: 78px;
}

p, b, i, h1, h2, h3, h4, h5, h6 {
  opacity: 0.95;
}

.main-button {
  background-color: #FD824E;
  background: #FD824E;
  background: -moz-linear-gradient(72deg, #FF8F5C 20%, #FFB185 160%);
  background: -webkit-linear-gradient(72deg, #FF8F5C 20%, #FFB185 160%);
  background: linear-gradient(72deg, #FF8F5C 20%, #FFB185 160%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$naranja03, endColorstr=$naranja02, GradientType=1);
  transition: none;
  border-radius: 34px;
  font-family: "Figtree Medium", sans-serif;
  font-size: 16px;
  color: white;
  border: none;
  letter-spacing: 1.33px;
}

.main-button:hover {
  background: #FFB185;
}

.main-button:focus {
  background: #FFB185;
}

.main-button:active {
  background: #FFB185;
}

.arrow {
  width: 24px;
  margin-top: -2px;
}

.navbar-toggler {
  z-index: 9999999;
}

.navbar-social-icon {
  background-color: #F2F2F2;
  border: none;
}

.navbar-social-icon > svg {
  fill: #527BFF;
  transform: translateY(-2px);
}

#basic-navbar-nav {
  z-index: 99999;
}

.text-navbar-nav {
  font-family: "Figtree SemiBold", sans-serif;
  font-size: 17px;
  transition: 0.12s;
}

.button-navbar:hover {
  color: white;
}

.header-navbar {
  background-color: transparent;
  position: fixed;
  padding: 13px 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: rgba(0, 0, 0, 0);
}

.navbar-collapse-social-icons {
  align-self: flex-start;
  display: flex;
  flex-grow: 3;
  align-items: self-end;
  width: fit-content;
}

section.homeTitle {
  position: relative;
  overflow-x: clip;
  padding: 0 0 5rem 0;
}

div.homeTitleContainer {
  position: fixed !important;
  z-index: 1;
}

.home-empty-space {
  position: relative;
  height: 250px;
  margin-bottom: 0;
}

@media (min-width: 992px) {
  .home-empty-space {
    height: 400px;
    margin-bottom: -150px;
  }
}
.home-main-button {
  font-family: "Figtree Bold", sans-serif;
  font-size: 17.5px;
  border: 1px solid #FF8F5C;
  background-color: white;
  color: #FD824E;
  border-radius: 9px;
  transition: all, 1s, linear;
  position: relative;
}

.home-main-button:hover {
  color: #FD824E;
  background: -moz-linear-gradient(90deg, #FEE8D7 20%, #FFF5ED 160%);
  background: -webkit-linear-gradient(90deg, #FEE8D7 20%, #FFF5ED 160%);
  background: linear-gradient(90deg, #FEE8D7 20%, #FFF5ED 160%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$naranja07, endColorstr=$naranja06, GradientType=1);
  transform: scaleX(1.03);
}

section.homeTitle h1 {
  font-family: "Figtree Bold", sans-serif;
  font-size: 34px;
  letter-spacing: -0.5px;
  color: #2E60FF;
}

@media (min-width: 992px) {
  section.homeTitle h1 {
    font-size: 40px;
  }
}
section.homeTitle h3 {
  font-family: "Figtree Light", sans-serif;
  font-size: 16px;
  color: #3D3D3D;
}

section.homeTitle h3 b {
  font-weight: bold;
}

@media (min-width: 992px) {
  section.homeTitle h1 {
    font-size: 43px;
  }
  section.homeTitle h3 {
    font-size: 18px;
  }
}
@media (min-width: 992px) {
  section.homeTitle h1 {
    font-size: 35px;
  }
  section.homeTitle h3 {
    font-size: 20px;
  }
}
/*cellphone animation*/
div.home-cellphone-image-container {
  position: relative;
  margin: 0 auto;
  width: 226.85px;
  height: 458.25px;
}

div.home-animation-item-0 {
  width: 202.15px;
  margin-top: -423.15px;
  margin-left: 11.7px;
}

div.home-animation-item-1 {
  width: 57.2px;
  margin-top: -332.15px;
  margin-left: 195.65px;
}

div.home-animation-item-2 {
  width: 58.5px;
  margin-top: -384.8px;
  margin-left: -26px;
}

div.home-animation-item-3 {
  width: 43.55px;
  margin-top: -189.15px;
  margin-left: -16.25px;
}

div.home-animation-item-4 {
  width: 50.7px;
  margin-top: -411.45px;
  margin-left: 18.2px;
}

div.home-animation-item-5 {
  width: 126.1px;
  margin-top: -262.6px;
  margin-left: 68.25px;
}

div.home-animation-item-6 {
  width: 92.95px;
  margin-top: -92.95px;
  margin-left: 113.1px;
}

div.home-animation-item-7 {
  width: 148.2px;
  margin-top: -351px;
  margin-left: 56.55px;
}

div.home-animation-item-8 {
  width: 120.9px;
  margin-top: -315.9px;
  margin-left: 43.55px;
}

div.home-animation-item-9 {
  width: 25.35px;
  margin-top: -312px;
  margin-left: 16.9px;
}

div.home-animation-item-10 {
  width: 25.35px;
  margin-top: -127.4px;
  margin-left: 16.9px;
}

div.home-animation-item-11 {
  width: 150.15px;
  margin-top: -132.6px;
  margin-left: 43.55px;
}

@media (min-width: 992px) {
  div.home-animation-item-0 {
    width: 311px;
    margin-top: -651px;
    margin-left: 18px;
  }
  div.home-cellphone-image-container {
    width: 349px;
    height: 705px;
  }
  div.home-animation-item-1 {
    width: 88px;
    margin-top: -511px;
    margin-left: 301px;
  }
  div.home-animation-item-2 {
    width: 90px;
    margin-top: -592px;
    margin-left: -40px;
  }
  div.home-animation-item-3 {
    width: 67px;
    margin-top: -291px;
    margin-left: -25px;
  }
  div.home-animation-item-4 {
    width: 78px;
    margin-top: -633px;
    margin-left: 28px;
  }
  div.home-animation-item-5 {
    width: 194px;
    margin-top: -404px;
    margin-left: 105px;
  }
  div.home-animation-item-6 {
    width: 143px;
    margin-top: -143px;
    margin-left: 174px;
  }
  div.home-animation-item-7 {
    width: 228px;
    margin-top: -540px;
    margin-left: 87px;
  }
  div.home-animation-item-8 {
    width: 186px;
    margin-top: -486px;
    margin-left: 67px;
  }
  div.home-animation-item-9 {
    width: 39px;
    margin-top: -480px;
    margin-left: 26px;
  }
  div.home-animation-item-10 {
    width: 39px;
    margin-top: -196px;
    margin-left: 26px;
  }
  div.home-animation-item-11 {
    width: 231px;
    margin-top: -204px;
    margin-left: 67px;
  }
}
/*cellphone animation*/
div.home-cellphone-animation-container {
  position: relative;
  z-index: 1;
}

div.home-cellphone-animation-item {
  position: absolute;
}

div.cp-scroll-reference {
  position: absolute;
  height: 320px;
  width: 100%;
}

@media (min-width: 992px) {
  div.cp-scroll-reference {
    height: 600px;
  }
}
.cpZIndexContainer {
  z-index: 3;
  position: relative;
}

img.home-arc-background {
  z-index: -99;
  position: absolute;
  transform-origin: center;
  margin-top: 30lvh;
  width: 150%;
  left: 0;
}

@media (min-width: 992px) {
  img.home-arc-background {
    margin-top: 30lvh;
    width: 125%;
  }
}
/* DETAILS*/
section.details-section {
  overflow-x: clip;
}

div.details-page-container {
  position: relative;
}

div.details-empty-space {
  height: 0;
  position: relative;
}

@media (min-width: 992px) {
  div.details-empty-space {
    height: 20lvh;
  }
}
div.details-page-empty-space {
  display: block;
  height: 100lvh;
  width: 100%;
}

@media (min-width: 992px) {
  div.details-page-empty-space {
    height: 120lvh;
  }
}
div.details-page-fixed-container {
  position: fixed;
  top: 0;
  left: 0;
  display: grid;
  align-content: center;
  width: 895px;
  height: 100lvh;
}

img.details-progress-img {
  width: 10px;
  position: fixed;
  top: 44lvh;
}

.details-page-img {
  position: relative;
  max-height: 322px;
}

@media (min-width: 992px) {
  .details-page-img {
    max-height: inherit;
  }
}
.details-page-fixed-container-col {
  max-width: 100vw;
}

@media (min-width: 992px) {
  .details-page-fixed-container-col {
    max-width: initial;
  }
}
.details-page-button {
  font-family: "Figtree Medium", sans-serif;
  font-size: 12px;
  padding: 0.5rem 1.25rem;
  pointer-events: none;
  letter-spacing: 1px;
  width: 100%;
}

@media (min-width: 992px) {
  .details-page-button {
    font-size: 12px;
    padding: 0.75rem 1.5rem;
    width: inherit;
  }
}
.details-page-button-invisible button {
  transition: background-color 0.33s;
}

.details-page-button-invisible {
  background: transparent;
  position: relative;
  border: none;
  color: white;
  text-align: start;
  padding: 0;
  transition: transform 0.2s;
}

.details-page-button-invisible:hover {
  background: none;
  border: none;
  color: white;
  text-align: start;
  padding: 0;
  transform: scale(1.02);
}

.details-page-button-invisible:hover button {
  background-color: #FF8F5C;
}

.details-page-button-invisible:active {
  background: none;
  border: none;
  color: white;
  text-align: start;
  padding: 0;
}

.details-page-button-invisible:first-child:active {
  background-color: transparent;
  border: none;
}

.details-page-txt {
  color: white;
  margin-top: 0;
}

@media (min-width: 992px) {
  .details-page-txt {
    margin-top: 2rem;
  }
}
.details-page-heading {
  font-family: "Figtree Medium", sans-serif;
  font-size: 22px;
  margin-bottom: 0.5rem;
  letter-spacing: 0.66px;
  line-height: 1.33;
}

.details-page-paragraph {
  font-family: "Figtree light", sans-serif;
  font-size: 14px;
  line-height: 1.25;
  letter-spacing: 0.5px;
}

.details-page-paragraph b {
  letter-spacing: 1px;
}

@media (min-width: 576px) {
  .details-page-heading {
    font-family: "Figtree Medium", sans-serif;
    font-size: 24px;
    margin-bottom: 1rem;
    letter-spacing: 0.66px;
    line-height: 1.33;
  }
  .details-page-paragraph {
    font-family: "Figtree light", sans-serif;
    font-size: 16px;
    line-height: 1.25;
    letter-spacing: 0.5px;
  }
  .details-page-paragraph b {
    letter-spacing: 1px;
  }
}
@media (min-width: 992px) {
  .details-page-heading {
    font-size: 32px;
    margin-bottom: 1.5rem;
  }
  .details-page-paragraph {
    font-size: 16px;
    letter-spacing: 0.33px;
    line-height: 1.45;
  }
  .details-page-paragraph b {
    letter-spacing: 1.5px;
  }
  .details-page-button {
    font-size: 14px;
    width: inherit;
  }
}
@media (min-width: 1200px) {
  .details-page-heading {
    font-size: 38px;
    margin-bottom: 1.5rem;
  }
  .details-page-paragraph {
    font-size: 18px;
    line-height: 1.45;
    letter-spacing: 0.33px;
  }
  .details-page-paragraph b {
    letter-spacing: 1.5px;
  }
  .details-page-button {
    font-size: 16px;
  }
}
.glow-flipped {
  --webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.features-container {
  position: relative;
  z-index: 1;
}

.features-title-container h2 {
  font-family: "Figtree Bold", sans-serif;
  font-size: 28px;
  text-align: center;
  color: #2E60FF;
  line-height: 1;
}

.features-title-container p {
  font-family: "Figtree Regular", sans-serif;
  font-size: 18px;
  text-align: center;
  color: #3D3D3D;
  line-height: 1.2;
}

.feature-item {
  max-width: 383px;
}

.feature-item:nth-child(2) {
  order: 2;
}

@media (min-width: 992px) {
  .feature-item:nth-child(2) {
    order: inherit;
  }
}
.feature-item h4 {
  font-family: "Figtree Bold", sans-serif;
  font-size: 26px;
  color: #3D3D3D;
}

.feature-item p {
  font-family: "Figtree Regular", sans-serif;
  font-size: 17px;
  color: #3D3D3D;
  line-height: 1.2;
}

.features-item-container {
  justify-content: space-evenly;
}

.features-scroll-reference {
  position: absolute;
  top: 54lvh;
  left: 0;
  width: 100%;
  height: 100%;
}

.feature-item img {
  border-top-color: #FEE8D7;
  border-right-color: #FEC59F;
  border-style: solid;
  border-radius: 32px;
}

.id-features-reference {
  height: 10px;
  transform: translateY(-40px);
}

@media (min-width: 992px) {
  .id-features-reference {
    transform: translateY(-140px);
  }
}
div.background-fixed {
  height: 150lvh;
  z-index: -9;
  position: fixed;
  top: 0;
  width: 150vw;
  left: -50vw;
}

div.background-fixed-blue {
  background: #2E60FF;
  background: -moz-linear-gradient(90deg, #003AF5 0%, #527BFF 90%);
  background: -webkit-linear-gradient(90deg, #003AF5 0%, #527BFF 90%);
  background: linear-gradient(90deg, #003AF5 0%, #527BFF 90%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$azul09, endColorstr=$azul07, GradientType=1);
}

div.background-fixed-white {
  background: white;
}

section.stories-section {
  z-index: 2;
  position: relative;
  overflow-x: clip;
}

.stories-background {
  width: 100%;
  height: 100%;
  margin-top: 0;
  position: absolute;
  background-color: #F8F8F8;
  z-index: 1;
  border-radius: 50% 50% 0 0;
  transform-origin: top;
  transform: scaleX(2.5);
}

.stories-row-container {
  min-height: 505px;
}

@media (min-width: 992px) {
  .stories-background {
    transform: scaleX(1.5);
  }
  .stories-row-container {
    min-height: inherit;
  }
}
.stories-card-container {
  z-index: 2;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.05) 0 0 20px;
  border-radius: 20px;
}

.stories-container-item {
  position: absolute;
}

.stories-controls {
  position: absolute;
  top: 50lvh;
  z-index: 3;
  width: 100%;
  display: flex;
  opacity: 0.4;
}

@media (min-width: 992px) {
  .stories-controls {
    opacity: 0.66;
  }
}
.stories-controls button {
  border-radius: 50%;
  background-color: #2E60FF;
  border: 0;
}

.stories-controls button:hover {
  background-color: #819FFF;
}

.stories-controls button:focus {
  background-color: #2E60FF;
}

.stories-controls button:active {
  background-color: #2E60FF;
}

.stories-empty-space {
  height: 92lvh;
}

@media (min-width: 992px) {
  .stories-empty-space {
    height: 62lvh;
  }
}
.stories-col-container h4 {
  font-family: "Figtree SemiBold", sans-serif;
  font-size: 12px;
  line-height: 1.6;
  letter-spacing: 5.5px;
  color: #3D3D3D;
}

.stories-col-container blockquote {
  font-family: "Figtree SemiBold", sans-serif;
  font-size: 20px;
  line-height: 1.2;
  color: #3D3D3D;
}

@media (min-width: 1200px) {
  .stories-col-container h4 {
    font-size: 12px;
    line-height: 1;
    letter-spacing: 4px;
  }
  .stories-col-container blockquote {
    font-size: 24px;
    line-height: 1.4;
  }
}
.stories-progress {
  position: absolute;
  z-index: 1;
  width: 100%;
  justify-content: center;
  display: none;
}

@media (min-width: 992px) {
  .stories-progress {
    display: flex;
  }
}
.stories-progress img {
  top: 0;
  position: absolute;
}

.stories-image-col-container {
  padding: 2.2rem;
  margin-bottom: -1rem;
  margin-top: -1rem;
}

@media (min-width: 992px) {
  .stories-image-col-container {
    padding: 0.5rem;
    margin-bottom: 0;
    margin-top: 0;
  }
}
.stories-cta-button {
  background-color: white;
  border-radius: 30px;
  border-color: #002DC7;
  color: #002DC7;
  width: 100%;
}

section.security-section {
  z-index: 2;
  position: relative;
  background-color: #F2F5FF;
  overflow-x: clip;
}

.security-row-container {
  background-color: #CDD9FF;
  border-radius: 24px;
  z-index: 2;
  position: relative;
}

img.security-img {
  margin: 0 auto;
  position: relative;
  display: block;
}

.security-row-container h4 {
  font-family: "Figtree Bold", sans-serif;
  font-size: 26px;
  color: #002DC7;
}

.security-row-container p {
  font-family: "Figtree Regular", sans-serif;
  font-size: 17px;
  color: #002DC7;
  line-height: 1.3;
}

.security-background {
  width: 100%;
  height: 100%;
  margin-top: 0;
  position: absolute;
  background-color: #F2F5FF;
  border-radius: 0 0 50% 50%;
  transform-origin: top;
  transform: scaleX(2.5);
}

@media (min-width: 992px) {
  .security-background {
    transform: scaleX(1.5);
  }
}
@media (min-width: 992px) {
  .security-row-container h4 {
    font-size: 30px;
  }
  .security-row-container p {
    font-size: 20px;
    line-height: 1.3;
  }
}
.about-section {
  z-index: 2;
  position: relative;
  padding-top: 7.5rem;
}

.about-section h4 {
  color: #001D80;
  font-family: "Figtree Bold", sans-serif;
  font-size: 25px;
}

.about-section p {
  color: #001D80;
  font-family: "Figtree Regular", sans-serif;
  font-size: 16px;
  line-height: 1.3;
  letter-spacing: 1px;
}

.carrousel-container {
  justify-content: space-around;
  display: flex;
  align-items: center;
}

.carrousel-container-mobile {
  width: auto;
}

.carrousel-item {
  display: inline-block;
}

.carrousel-empty-space {
  height: 81px;
  display: flex;
  justify-content: space-between;
}

.carrousel-glow {
  position: relative;
  height: 100%;
  display: inline-block;
}

.carrousel-glow-inverse {
  transform: scaleX(-1);
}

.form-section {
  position: relative;
  z-index: 1;
}

.form-section-container {
  background-color: #2E60FF;
  border-radius: 22px;
}

.form-section-container h4 {
  font-family: "Figtree SemiBold", sans-serif;
  color: white;
  font-size: 18px;
}

@media (min-width: 992px) {
  .form-section-container h4 {
    font-size: 26px;
    line-height: 1.3;
  }
}
.form-section-container p {
  font-family: "Figtree Light", sans-serif;
  color: white;
  font-size: 14px;
  letter-spacing: 0.5px;
}

@media (min-width: 992px) {
  .form-section-container p {
    font-size: 17px;
    letter-spacing: 0.5px;
  }
}
.form-section label {
  font-family: "Figtree Regular", sans-serif;
  color: white;
  font-size: 14px;
  letter-spacing: 0.5px;
}

.form-section input {
  border-radius: 21px;
}

.form-section input::placeholder {
  font-size: 13px;
  padding-left: 0.25rem;
}

.form-section .main-button {
  font-size: 14px;
}

.form-section .main-button:hover {
  color: white;
}

.form-image-container img {
  transform: translateY(-50px);
}

.id-contact-reference {
  height: 10px;
  transform: translateY(-120px);
}

@media (min-width: 992px) {
  .id-contact-reference {
    transform: translateY(-140px);
  }
}
.footer-logo {
  width: 256px;
}

.footer-social-icon {
  background-color: #F2F5FF;
  border: none;
  transition: all 0.1s linear;
}

.footer-social-icon > svg {
  fill: #2E60FF;
  transform: translateY(-2px);
}

.footer-social-icon:hover {
  background-color: #CDD9FF;
}

.footer-social-icon:hover > svg {
  background-color: #CDD9FF;
  transform: translateY(-2px) scale(1.1);
}

.footer-nav li {
  color: #001D80;
  font-family: "Figtree Medium", sans-serif;
  font-size: 17px;
  transition: transform 50ms ease-in-out;
  width: 100%;
}

.footer-nav li a {
  text-align: center;
}

@media (min-width: 992px) {
  .footer-nav li {
    width: initial !important;
  }
  .footer-nav li a {
    text-align: left;
  }
}
.footer-nav li:hover {
  transform: scale(1.06);
}

.thanks-section .container {
  height: 66lvh;
}

.thanks-section .row {
  height: 100%;
}

.thanks-section h4 {
  font-family: "Figtree Bold", sans-serif;
  text-align: center;
  color: white;
  font-size: 34px;
  margin-bottom: 0.5rem;
  letter-spacing: 0.66px;
  line-height: 1.33;
}

.thanks-section p {
  text-align: center;
  color: white;
  font-family: "Figtree light", sans-serif;
  font-size: 18px;
  line-height: 1.25;
  letter-spacing: 0.5px;
}

.thanks-button {
  width: auto !important;
}

.error-section .container {
  height: 66lvh;
}

.error-section .row {
  height: 100%;
}

.error-section h4 {
  font-family: "Figtree Bold", sans-serif;
  text-align: center;
  color: white;
  font-size: 34px;
  margin-bottom: 0.5rem;
  letter-spacing: 0.66px;
  line-height: 1.33;
}

.error-section p {
  text-align: center;
  color: white;
  font-family: "Figtree light", sans-serif;
  font-size: 18px;
  line-height: 1.25;
  letter-spacing: 0.5px;
}

.error-button {
  width: auto !important;
}

.scroll-reference {
  width: 100%;
  height: 1px;
  opacity: 0;
}

