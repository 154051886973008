.features-section {

}
.features-container{
  position: relative;
  z-index: 1;
}

.features-title-container h2 {
  font-family: "Figtree Bold", sans-serif;
  font-size: 28px;
  text-align: center;
  color: $azul08;
  line-height: 1;

}

.features-title-container p {
  font-family: "Figtree Regular", sans-serif;
  font-size: 18px;
  text-align: center;
  color: $gris02;
  line-height: 1.2;
}

.feature-item {
  max-width: 383px;
}
.feature-item:nth-child(2){
  order: 2;
}
@include media-breakpoint-up(lg) {
  .feature-item:nth-child(2){
    order: inherit;
  }
}


.feature-item h4 {
  font-family: "Figtree Bold", sans-serif;
  font-size: 26px;
  color: $gris02;
}

.feature-item p {
  font-family: "Figtree Regular", sans-serif;
  font-size: 17px;
  color: $gris02;
  line-height: 1.2;
}

.features-item-container {
  justify-content: space-evenly;
}

.features-scroll-reference {
  position: absolute;
  top: 54lvh;
  left: 0;
  width: 100%;
  height: 100%;
}

.feature-item img {
  border-top-color: $naranja06;
  border-right-color: $naranja04;
  border-style: solid;
  border-radius: 32px;
}
.id-features-reference {
  height: 10px;
  transform: translateY(-40px);
}
@include media-breakpoint-up(lg) {
  .id-features-reference {
    transform: translateY(-140px);
  }
}