.footer-logo {
  width: 256px;
}

.footer-social-icon {
  background-color: $azul00;
  border: none;
  transition: all .1s linear;
}

.footer-social-icon > svg {
  fill: $azul08;
  transform: translateY(-2px);
}

.footer-social-icon:hover {
  background-color: $azul02;
}

.footer-social-icon:hover > svg {
  background-color: $azul02;
  transform: translateY(-2px) scale(1.1);
}

.footer-nav {

}

.footer-nav li {
  color: $azul12;
  font-family: 'Figtree Medium', sans-serif;
  font-size: 17px;
  transition: transform 50ms ease-in-out;
  width: 100%;
}
.footer-nav li a {
  text-align: center;
}
@include media-breakpoint-up(lg) {
  .footer-nav li {
    width: initial !important;
  }
  .footer-nav li a {
    text-align: left;
  }
}

.footer-nav li:hover {
  transform: scale(1.06);
}