section.stories-section {
  z-index: 2;
  position: relative;
  overflow-x: clip;
}

.stories-background {
  width: 100%;
  height: 100%;
  margin-top: 0;
  position: absolute;
  background-color: $gris10;
  z-index: 1;
  border-radius: 50% 50% 0 0;
  transform-origin: top;
  transform: scaleX(2.5);
}
.stories-row-container {
  min-height: 505px;
}
@include media-breakpoint-up(lg) {
  .stories-background {
    transform: scaleX(1.5);
  }
  .stories-row-container {
    min-height: inherit;
  }
}
.stories-card-container {
  z-index: 2;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.05) 0 0 20px;
  border-radius: 20px;
}

.stories-container-item {
  position: absolute;
}

.stories-controls {
  position: absolute;
  top: 50lvh;
  z-index: 3;
  width: 100%;
  display: flex;
  opacity: .4;
}
@include media-breakpoint-up(lg) {
  .stories-controls {
    opacity: .66;
  }
}

.stories-controls button {
  border-radius: 50%;
  background-color: $azul08;
  border: 0;
}
.stories-controls button:hover {
  background-color: $azul05;
}
.stories-controls button:focus {
  background-color: $azul08;
}
.stories-controls button:active {
  background-color: $azul08;
}


.stories-empty-space {
  height: 92lvh;
}

@include media-breakpoint-up(lg) {
  .stories-empty-space {
    height: 62lvh;
  }
}

.stories-col-container h4 {
  font-family: "Figtree SemiBold", sans-serif;
  font-size: 12px;
  line-height: 1.6;
  letter-spacing: 5.5px;
  color: $gris02;
}

.stories-col-container blockquote {
  font-family: "Figtree SemiBold", sans-serif;
  font-size: 20px;
  line-height: 1.2;
  color: $gris02;
}

@include media-breakpoint-up(xl) {
  .stories-col-container h4 {
    font-size: 12px;
    line-height: 1;
    letter-spacing: 4px;
  }
  .stories-col-container blockquote {
    font-size: 24px;
    line-height: 1.4;
  }
}


.stories-progress {
  position: absolute;
  z-index: 1;
  width: 100%;
  justify-content: center;
  display: none;
}
@include media-breakpoint-up(lg) {
  .stories-progress {
    display: flex;
  }
}
.stories-progress img {
  top: 0;
  position: absolute;
}

.stories-image-col-container {
  padding: 2.2rem;
  margin-bottom: -1rem;
  margin-top: -1rem;
}

@include media-breakpoint-up(lg) {
  .stories-image-col-container {
    padding: .5rem;
    margin-bottom: 0;
    margin-top: 0;
  }
}
.stories-cta-button {
  background-color: white;
  border-radius: 30px;
  border-color: #002DC7;
  color: #002DC7;
  width: 100%;
}