
.navbar-toggler {
  z-index: 9999999;
}

.navbar-social-icon {
  background-color: $gris09;
  border: none;
}

.navbar-social-icon > svg {
  fill: $azul07;
  transform: translateY(-2px);
}

#basic-navbar-nav {
  z-index: 99999;
}

.text-navbar-nav {
  font-family: 'Figtree SemiBold', sans-serif;
  font-size: 17px;
  transition: .12s;
}

.text-navbar-nav:hover {
  //transform: scale(1.06);
}

.button-navbar:hover {
  //transform: scale(1.02);
  color: white;
}

.header-navbar {
  background-color: transparent;
  position: fixed;
  padding: 13px 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: rgba(0,0,0,0);
}

.navbar-collapse-social-icons {
  align-self: flex-start;
  display: flex;
  flex-grow: 3;
  align-items: self-end;
  width: fit-content;
}