div.background-fixed {
  height: 150lvh;
  z-index: -9;
  position: fixed;
  top: 0;
  width: 150vw;
  left: -50vw;
}
div.background-fixed-blue {
  background: $azul08;
  background: -moz-linear-gradient(90deg, $azul09 0%, $azul07 90%);
  background: -webkit-linear-gradient(90deg, $azul09 0%, $azul07 90%);
  background: linear-gradient(90deg, $azul09 0%, $azul07 90%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$azul09, endColorstr=$azul07, GradientType=1);
}
div.background-fixed-white {
  background: white;
}