//text
@font-face {
  font-family: 'Figtree Black';
  src: url('../fonts/Figtree-Black.woff2') format('woff2'),
  url('../fonts/Figtree-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Figtree Light';
  src: url('../fonts/Figtree-Light.woff2') format('woff2'),
  url('../fonts/Figtree-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Figtree Bold';
  src: url('../fonts/Figtree-Bold.woff2') format('woff2'),
  url('../fonts/Figtree-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Figtree ExtraBold';
  src: url('../fonts/Figtree-ExtraBoldItalic.woff2') format('woff2'),
  url('../fonts/Figtree-ExtraBoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Figtree Medium';
  src: url('../fonts/Figtree-Medium.woff2') format('woff2'),
  url('../fonts/Figtree-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Figtree';
  src: url('../fonts/Figtree-Italic.woff2') format('woff2'),
  url('../fonts/Figtree-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Figtree Bold';
  src: url('../fonts/Figtree-BoldItalic.woff2') format('woff2'),
  url('../fonts/Figtree-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Figtree Light';
  src: url('../fonts/Figtree-LightItalic.woff2') format('woff2'),
  url('../fonts/Figtree-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Figtree ExtraBold';
  src: url('../fonts/Figtree-ExtraBold.woff2') format('woff2'),
  url('../fonts/Figtree-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Figtree Black';
  src: url('../fonts/Figtree-BlackItalic.woff2') format('woff2'),
  url('../fonts/Figtree-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Figtree Medium';
  src: url('../fonts/Figtree-MediumItalic.woff2') format('woff2'),
  url('../fonts/Figtree-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Figtree SemiBold';
  src: url('../fonts/Figtree-SemiBoldItalic.woff2') format('woff2'),
  url('../fonts/Figtree-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Figtree SemiBold';
  src: url('../fonts/Figtree-SemiBold.woff2') format('woff2'),
  url('../fonts/Figtree-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Figtree Regular';
  src: url('../fonts/Figtree-Regular.woff2') format('woff2'),
  url('../fonts/Figtree-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// scss-docs-start color-variables
$azul12: #001D80;
$azul11: #0025A3;
$azul10: #002DC7;
$azul09: #003AF5;
$azul08: #2E60FF;
$azul07: #527BFF;
$azul06: #6E90FF;
$azul05: #819FFF;
$azul04: #9AB2FF;
$azul03: #B8C9FF;
$azul02: #CDD9FF;
$azul01: #D9E2FF;
$azul00: #F2F5FF;
$gris01: #303030;
$gris02: #3D3D3D;
$gris03: #696969;
$gris04: #989898;
$gris05: #B2B2B2;
$gris06: #BEBEBE;
$gris07: #CBCBCB;
$gris08: #E5E5E5;
$gris09: #F2F2F2;
$gris10: #F8F8F8;
$naranja00: #F56529;
$naranja01: #FD824E;
$naranja02: #FF8F5C;
$naranja03: #FFB185;
$naranja04: #FEC59F;
$naranja05: #FED8BD;
$naranja06: #FEE8D7;
$naranja07: #FFF5ED;
// scss-docs-end color-variables

// scss-docs-start theme-color-variables
$primary: $azul08;
$secondary: $azul01;
$tertiary: $naranja02;
$theme-colors: (
        "primary": $primary,
        "secondary": $secondary,
        "tertiary": $tertiary
);
// scss-docs-end theme-color-variables

//layout
$container-max-widths: (
        xxl: 1440px
);
//navbar
$navbar-light-icon-color: $naranja01;
$navbar-light-toggler-border-color: $naranja04;
//offcanvas
$offcanvas-horizontal-width: 340px;
//buttons
$btn-link-hover-color: white;

@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/navbar";
@import "../../node_modules/bootstrap/scss/offcanvas";
@import "../../node_modules/bootstrap/scss/nav";
@import "../../node_modules/bootstrap/scss/containers";

// custom
@import "base";
@import "Header/header";
@import "Home/home";
@import "Home/Cellphone/cellphone-animation";
@import "Details/arc-background";
@import "Details/details";
@import "Features/Features";
@import "Background/Background";
@import "Stories/Stories";
@import "Security/Security";
@import "About/About";
@import "Carrousel/Carrousel";
@import "Form/FormSection";
@import "Footer/Footer";
@import "ThankYou/HeaderSimplifed";
@import "ThankYou/ThankYou";
@import "ErrorPage/ErrorPage";

.scroll-reference {
  width: 100%;
  height: 1px;
  opacity: 0;
}