/* DETAILS*/
section.details-section {
  overflow-x: clip;
}

div.details-page-container {
  position: relative;
}

@include media-breakpoint-up(lg) {
  div.details-page-container {
  }
}

div.details-empty-space {
  height: 0;
  position: relative;
}

@include media-breakpoint-up(lg) {
  div.details-empty-space {
    height: 20lvh;
  }
}

div.details-page-empty-space {
  display: block;
  height: 100lvh;
  width: 100%;
}

@include media-breakpoint-up(lg) {
  div.details-page-empty-space {
    height: 120lvh;
  }
}


div.details-page-fixed-container {
  position: fixed;
  top: 0;
  left: 0;
  display: grid;
  align-content: center;
  width: 895px;
  height: 100lvh;
}

img.details-progress-img {
  width: 10px;
  position: fixed;
  top: 44lvh;
}

.details-page-img {
  position: relative;
  max-height: 322px;
}

@include media-breakpoint-up(lg) {
  .details-page-img {
    max-height: inherit;
  }
}

.details-page-fixed-container-col {
  max-width: 100vw;
}

@include media-breakpoint-up(lg) {
  .details-page-fixed-container-col {
    max-width: initial;
  }
}

.details-page-button {
  font-family: "Figtree Medium", sans-serif;
  font-size: 12px;
  padding: 0.5rem 1.25rem;
  pointer-events: none;
  letter-spacing: 1px;
  width: 100%;
}

@include media-breakpoint-up(lg) {
  .details-page-button {
    font-size: 12px;
    padding: 0.75rem 1.5rem;
    width: inherit;
  }
}

.details-page-button-invisible button {
  transition: background-color .33s;
}

.details-page-button-invisible {
  background: transparent;
  position: relative;
  border: none;
  color: white;
  text-align: start;
  padding: 0;
  transition: transform .2s;
  //z-index: 99;
}

.details-page-button-invisible:hover {
  background: none;
  border: none;
  color: white;
  text-align: start;
  padding: 0;
  transform: scale(1.02);
}

.details-page-button-invisible:hover button {
  background-color: $naranja02;
}

.details-page-button-invisible:active {
  background: none;
  border: none;
  color: white;
  text-align: start;
  padding: 0;
}

.details-page-button-invisible:first-child:active {
  background-color: transparent;
  border: none;
}

.details-page-txt {
  color: white;
  margin-top: 0;
}

@include media-breakpoint-up(lg) {
  .details-page-txt {
    margin-top: 2rem;
  }
}

.details-page-heading {
  font-family: "Figtree Medium", sans-serif;
  font-size: 22px;
  margin-bottom: .5rem;
  letter-spacing: 0.66px;
  line-height: 1.33;
}

.details-page-paragraph {
  font-family: "Figtree light", sans-serif;
  font-size: 14px;
  line-height: 1.25;
  letter-spacing: 0.5px;
}

.details-page-paragraph b {
  letter-spacing: 1px;
}

@include media-breakpoint-up(sm) {
  .details-page-heading {
    font-family: "Figtree Medium", sans-serif;
    font-size: 24px;
    margin-bottom: 1rem;
    letter-spacing: 0.66px;
    line-height: 1.33;
  }

  .details-page-paragraph {
    font-family: "Figtree light", sans-serif;
    font-size: 16px;
    line-height: 1.25;
    letter-spacing: 0.5px;
  }

  .details-page-paragraph b {
    letter-spacing: 1px;
  }
}

@include media-breakpoint-up(lg) {
  .details-page-heading {
    font-size: 32px;
    margin-bottom: 1.5rem;
  }
  .details-page-paragraph {
    font-size: 16px;
    letter-spacing: 0.33px;
    line-height: 1.45;
  }
  .details-page-paragraph b {
    letter-spacing: 1.5px;
  }
  .details-page-button {
    font-size: 14px;
    width: inherit;
  }
}

@include media-breakpoint-up(xl) {
  .details-page-heading {
    font-size: 38px;
    margin-bottom: 1.5rem;
  }
  .details-page-paragraph {
    font-size: 18px;
    line-height: 1.45;
    letter-spacing: 0.33px;
  }
  .details-page-paragraph b {
    letter-spacing: 1.5px;
  }
  .details-page-button {
    font-size: 16px;
  }
}

.glow-flipped {
  --webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}