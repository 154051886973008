.carrousel-container {
  justify-content: space-around;
  display: flex;
  align-items: center;

}
.carrousel-container-mobile {
  width: auto;
}
.carrousel-item {
  display: inline-block;
}
.carrousel-empty-space {
  height: 81px;
  display: flex;
  justify-content: space-between;
}
.carrousel-glow{
  position: relative;
  height: 100%;
  display: inline-block;
}
.carrousel-glow-inverse{
transform: scaleX(-1);
}