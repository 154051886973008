section.security-section {
  z-index: 2;
  position: relative;
  background-color: $azul00;
  overflow-x: clip;
}

.security-row-container {
  background-color: $azul02;
  border-radius: 24px;
  z-index: 2;
  position: relative;
}

img.security-img {
  margin: 0 auto;
  position: relative;
  display: block;
}

.security-row-container h4 {
  font-family: "Figtree Bold", sans-serif;
  font-size: 26px;
  color: $azul10;
}

.security-row-container p {
  font-family: "Figtree Regular", sans-serif;
  font-size: 17px;
  color: $azul10;
  line-height: 1.3;
}

.security-background {
  width: 100%;
  height: 100%;
  margin-top: 0;
  position: absolute;
  background-color: $azul00;
  border-radius: 0 0 50% 50%;
  transform-origin: top;
  transform: scaleX(2.5);
}

@include media-breakpoint-up(lg) {
  .security-background {
    transform: scaleX(1.5);
  }
}

@include media-breakpoint-up(lg) {
  .security-row-container h4 {
    font-size: 30px;
  }
  .security-row-container p {
    font-size: 20px;
    line-height: 1.3;
  }
}