//home
section.homeTitle {
  position: relative;
  overflow-x: clip;
  padding: 0 0 5rem 0;
}

div.homeTitleContainer {
  position: fixed !important;
  z-index: 1;
}

.home-empty-space {
  position: relative;
  height: 250px;
  margin-bottom: 0;
}
@include media-breakpoint-up(lg) {
  .home-empty-space {
    height: 400px;
    margin-bottom: -150px;
  }
}


.home-main-button {
  font-family: "Figtree Bold", sans-serif;
  font-size: 17.5px;
  border: 1px solid $naranja02;
  background-color: white;
  color: $naranja01;
  border-radius: 9px;
  transition: all, 1s, linear;
  position: relative;
}

.home-main-button:hover {
  color: $naranja01;
  background: -moz-linear-gradient(90deg, $naranja06 20%, $naranja07 160%);
  background: -webkit-linear-gradient(90deg, $naranja06 20%, $naranja07 160%);
  background: linear-gradient(90deg, $naranja06 20%, $naranja07 160%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$naranja07, endColorstr=$naranja06, GradientType=1);
  transform: scaleX(1.03);
}

section.homeTitle h1 {
  font-family: "Figtree Bold", sans-serif;
  font-size: 34px;
  letter-spacing: -0.5px;
  color: $azul08;
}

@include media-breakpoint-up(lg) {
  section.homeTitle h1 {
    font-size: 40px;
  }
}

section.homeTitle h3 {
  font-family: "Figtree Light", sans-serif;
  font-size: 16px;
  color: $gris02;
}

section.homeTitle h3 b {
  font-weight: bold;
}

@include media-breakpoint-up(lg) {
  section.homeTitle h1 {
    font-size: 43px;
  }
  section.homeTitle h3 {
    font-size: 18px;
  }
}


@include media-breakpoint-up(lg) {
  section.homeTitle h1 {
    font-size: 35px;
  }
  section.homeTitle h3 {
    font-size: 20px;
  }
}
