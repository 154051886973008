/////////////// BACKGROUND

img.home-arc-background {
  z-index: -99;
  position: absolute;
  transform-origin: center;
  margin-top: 30lvh;
  width: 150%;
  left: 0;
}

@include media-breakpoint-up(lg) {
  img.home-arc-background {
    margin-top: 30lvh;
    width: 125%;
  }
}

