html {
  overflow-x: hidden !important;
  scroll-snap-type: y mandatory;
  overscroll-behavior-y: none;
  overflow-y: auto;
  scroll-behavior: auto !important;
}

body {
  padding-top: 78px;
}

p, b, i, h1, h2, h3, h4, h5, h6 {
  opacity: 0.95;
}

.main-button {
  background-color: $naranja01;
  background: $naranja01;
  background: -moz-linear-gradient(72deg, $naranja02 20%, $naranja03 160%);
  background: -webkit-linear-gradient(72deg, $naranja02 20%, $naranja03 160%);
  background: linear-gradient(72deg, $naranja02 20%, $naranja03 160%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$naranja03, endColorstr=$naranja02, GradientType=1);
  transition: none;
  border-radius: 34px;
  font-family: 'Figtree Medium', sans-serif;
  font-size: 16px;
  color: white;
  border: none;
  letter-spacing: 1.33px;
}

.main-button:hover {
  background: $naranja03;
}

.main-button:focus {
  background: $naranja03;
}

.main-button:active {
  background: $naranja03;
}

.arrow {
  width: 24px;
  margin-top: -2px;
}