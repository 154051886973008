.form-section {
  position: relative;
  z-index: 1;
}

.form-section-container {
  background-color: $azul08;
  border-radius: 22px;
}

.form-section-container h4 {
  font-family: "Figtree SemiBold", sans-serif;
  color: white;
  font-size: 18px;
}

@include media-breakpoint-up(lg) {
  .form-section-container h4 {
    font-size: 26px;
    line-height: 1.3;
  }
}

.form-section-container p {
  font-family: "Figtree Light", sans-serif;
  color: white;
  font-size: 14px;
  letter-spacing: .5px;
}

@include media-breakpoint-up(lg) {
  .form-section-container p {
    font-size: 17px;
    letter-spacing: .5px;
  }
}

.form-section label {
  font-family: "Figtree Regular", sans-serif;
  color: white;
  font-size: 14px;
  letter-spacing: .5px;
}

.form-section input {
  border-radius: 21px;
}

.form-section input::placeholder {
  font-size: 13px;
  padding-left: .25rem;
}

.form-section .main-button {
  font-size: 14px;
}

.form-section .main-button:hover {
  color: white;
}
.form-image-container img {
  transform: translateY(-50px);
}
.id-contact-reference {
  height: 10px;
  transform: translateY(-120px);
}
@include media-breakpoint-up(lg) {
  .id-contact-reference {
    transform: translateY(-140px);
  }
}