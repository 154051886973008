
/*cellphone animation*/

$c-0-width: 311px;
$c-0-m-top: -651px;
$c-0-m-left: 18px;

$c-01-width: 88px;
$c-01-m-top: -511px;
$c-01-m-left: 301px;

$c-02-width: 90px;
$c-02-m-top: -592px;
$c-02-m-left: -40px;

$c-03-width: 67px;
$c-03-m-top: -291px;
$c-03-m-left: -25px;

$c-04-width: 78px;
$c-04-m-top: -633px;
$c-04-m-left: 28px;

$c-05-width: 194px;
$c-05-m-top: -404px;
$c-05-m-left: 105px;

$c-06-width: 143px;
$c-06-m-top: -143px;
$c-06-m-left: 174px;

$c-07-width: 228px;
$c-07-m-top: -540px;
$c-07-m-left: 87px;

$c-08-width: 186px;
$c-08-m-top: -486px;
$c-08-m-left: 67px;

$c-09-width: 39px;
$c-09-m-top: -480px;
$c-09-m-left: 26px;

$c-10-width: 39px;
$c-10-m-top: -196px;
$c-10-m-left: 26px;

$c-11-width: 231px;
$c-11-m-top: -204px;
$c-11-m-left: 67px;


$c-item-size-mult: .65;
div.home-cellphone-image-container {
  position: relative;
  margin: 0 auto;
  width: 349px * $c-item-size-mult;
  height: 705px * $c-item-size-mult;
}

div.home-animation-item-0 {
  width: $c-0-width *  $c-item-size-mult;
  margin-top: $c-0-m-top * $c-item-size-mult;
  margin-left: $c-0-m-left * $c-item-size-mult;
}

div.home-animation-item-1 {
  width: $c-01-width *  $c-item-size-mult;
  margin-top: $c-01-m-top * $c-item-size-mult;
  margin-left: $c-01-m-left * $c-item-size-mult;
}

div.home-animation-item-2 {
  width: $c-02-width *  $c-item-size-mult;
  margin-top: $c-02-m-top * $c-item-size-mult;
  margin-left: $c-02-m-left * $c-item-size-mult;
}

div.home-animation-item-3 {
  width: $c-03-width *  $c-item-size-mult;
  margin-top: $c-03-m-top * $c-item-size-mult;
  margin-left: $c-03-m-left * $c-item-size-mult;
}

div.home-animation-item-4 {
  width: $c-04-width *  $c-item-size-mult;
  margin-top: $c-04-m-top * $c-item-size-mult;
  margin-left: $c-04-m-left * $c-item-size-mult;
}

div.home-animation-item-5 {
  width: $c-05-width *  $c-item-size-mult;
  margin-top: $c-05-m-top * $c-item-size-mult;
  margin-left: $c-05-m-left * $c-item-size-mult;
}

div.home-animation-item-6 {
  width: $c-06-width *  $c-item-size-mult;
  margin-top: $c-06-m-top * $c-item-size-mult;
  margin-left: $c-06-m-left * $c-item-size-mult;
}

div.home-animation-item-7 {
  width: $c-07-width  * $c-item-size-mult;
  margin-top: $c-07-m-top * $c-item-size-mult;
  margin-left: $c-07-m-left * $c-item-size-mult;
}

div.home-animation-item-8 {
  width: $c-08-width *  $c-item-size-mult;
  margin-top: $c-08-m-top * $c-item-size-mult;
  margin-left: $c-08-m-left * $c-item-size-mult;
}

div.home-animation-item-9 {
  width: $c-09-width *  $c-item-size-mult;
  margin-top: $c-09-m-top * $c-item-size-mult;
  margin-left: $c-09-m-left * $c-item-size-mult;
}

div.home-animation-item-10 {
  width: $c-10-width *  $c-item-size-mult;
  margin-top: $c-10-m-top * $c-item-size-mult;
  margin-left: $c-10-m-left * $c-item-size-mult;
}

div.home-animation-item-11 {
  width: $c-11-width *  $c-item-size-mult;
  margin-top: $c-11-m-top * $c-item-size-mult;
  margin-left: $c-11-m-left * $c-item-size-mult;
}

@include media-breakpoint-up(lg) {
  $c-item-size-mult: 1;
  div.home-animation-item-0 {
    width: $c-0-width *  $c-item-size-mult;
    margin-top: $c-0-m-top * $c-item-size-mult;
    margin-left: $c-0-m-left * $c-item-size-mult;
  }
  div.home-cellphone-image-container {
    width: 349px * $c-item-size-mult;
    height: 705px * $c-item-size-mult;
  }
  div.home-animation-item-1 {
    width: $c-01-width *  $c-item-size-mult;
    margin-top: $c-01-m-top * $c-item-size-mult;
    margin-left: $c-01-m-left * $c-item-size-mult;
  }
  div.home-animation-item-2 {
    width: $c-02-width *  $c-item-size-mult;
    margin-top: $c-02-m-top * $c-item-size-mult;
    margin-left: $c-02-m-left * $c-item-size-mult;
  }
  div.home-animation-item-3 {
    width: $c-03-width *  $c-item-size-mult;
    margin-top: $c-03-m-top * $c-item-size-mult;
    margin-left: $c-03-m-left * $c-item-size-mult;
  }
  div.home-animation-item-4 {
    width: $c-04-width *  $c-item-size-mult;
    margin-top: $c-04-m-top * $c-item-size-mult;
    margin-left: $c-04-m-left * $c-item-size-mult;
  }
  div.home-animation-item-5 {
    width: $c-05-width *  $c-item-size-mult;
    margin-top: $c-05-m-top * $c-item-size-mult;
    margin-left: $c-05-m-left * $c-item-size-mult;
  }
  div.home-animation-item-6 {
    width: $c-06-width *  $c-item-size-mult;
    margin-top: $c-06-m-top * $c-item-size-mult;
    margin-left: $c-06-m-left * $c-item-size-mult;
  }
  div.home-animation-item-7 {
    width: $c-07-width  * $c-item-size-mult;
    margin-top: $c-07-m-top * $c-item-size-mult;
    margin-left: $c-07-m-left * $c-item-size-mult;
  }
  div.home-animation-item-8 {
    width: $c-08-width *  $c-item-size-mult;
    margin-top: $c-08-m-top * $c-item-size-mult;
    margin-left: $c-08-m-left * $c-item-size-mult;
  }
  div.home-animation-item-9 {
    width: $c-09-width *  $c-item-size-mult;
    margin-top: $c-09-m-top * $c-item-size-mult;
    margin-left: $c-09-m-left * $c-item-size-mult;
  }
  div.home-animation-item-10 {
    width: $c-10-width *  $c-item-size-mult;
    margin-top: $c-10-m-top * $c-item-size-mult;
    margin-left: $c-10-m-left * $c-item-size-mult;
  }
  div.home-animation-item-11 {
    width: $c-11-width *  $c-item-size-mult;
    margin-top: $c-11-m-top * $c-item-size-mult;
    margin-left: $c-11-m-left * $c-item-size-mult;
  }
}

/*cellphone animation*/
div.home-cellphone-animation-container {
  position: relative;
  z-index: 1;
}
div.home-cellphone-animation-item {
  position: absolute;
}

div.cp-scroll-reference {
  position: absolute;
  height: 320px;
  width: 100%;
}
@include media-breakpoint-up(lg) {
  div.cp-scroll-reference {
    height: 600px;
  }
}

.cpZIndexContainer {
  z-index: 3;
  position: relative;
}